"use client";
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-scroll";
import Image from "next/image";
import LogoIcon from "/public/static/logo.png";
import MenuIcon from "/public/static/menu.png";
import { isMobile } from "react-device-detect";
import { Drawer } from "@mui/material";
import { useStaticContent } from "@/hooks/useStaticContent";
import { useRouter } from "next/router";

const NavBar: React.FC = () => {
  const router = useRouter();
  const { pathname, asPath, query, locale = "en" } = router;
  const staticText = useStaticContent();
  const [visible, setVisible] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [currentPage, setCurrentPage] = useState<string>("");
  const [backdropStatus, setBackdropStatus] = useState(false);

  // State for the currently selected locale
  const [selectedLang, setSelectedLang] = useState(locale);
  const handleClick = (languageLocale: any) => {
    setSelectedLang(languageLocale);
    setBackdropStatus(false);
  };
  const navClick = (key: string) => {
    console.log(key);
    // setCurrentPage(key)
  };
  const handleScroll = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 80) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
  };

  useEffect(() => {
    const cookieConsent = document.cookie
      ? document.cookie
          .split("; ")
          .find((row) => row.startsWith("hasCookieConsent="))
      : null;

    const cookieConsentString = cookieConsent
      ? cookieConsent.split("=")[1]
      : false;

    const hasCookieConsent = cookieConsentString === "true";

    if (selectedLang === "en") {
      if (hasCookieConsent) {
        document.cookie = `NEXT_LOCALE=en; maxage=${
          1000 * 60 * 60 * 24 * 7
        }; path=/`;
      }
      router.push({ pathname, query }, asPath, { locale: "en" });
    }
    if (selectedLang === "zh") {
      if (hasCookieConsent) {
        document.cookie = `NEXT_LOCALE=en; maxage=${
          1000 * 60 * 60 * 24 * 7
        }; path=/`;
      }
      router.push({ pathname, query }, asPath, { locale: "zh" });
    }
  }, [selectedLang]); //eslint-disable-line
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  if (isMobile) {
    //
    // @ts-ignore
    return (
      <div className={`navbar ${isSticky ? "fixed w-screen" : "w-screen"}`}>
        <div
          className={
            "h-[80px] relative bg-black px-[12px] box-border flex justify-between items-center"
          }
        >
          <Image
            className={"w-[140px] h-[48px] cursor-pointer"}
            src={LogoIcon}
            alt=""
            width={140}
            height={48}
          />
          <Image
            onClick={() => setVisible(true)}
            className={"w-[32px] h-[32px] cursor-pointer"}
            src={MenuIcon}
            alt=""
            width={32}
            height={32}
          />
          <Drawer
            anchor={"left"}
            open={visible}
            onClose={() => setVisible(!visible)}
          >
            <div
              className={
                "flex justify-between bg-black h-screen w-[60vw] flex-col items-center py-[30px] box-border"
              }
            >
              <div>
                <Image
                  className={"w-[140px] h-[48px] cursor-pointer"}
                  src={LogoIcon}
                  alt=""
                  width={140}
                  height={48}
                />
                <div
                  className={
                    "text-[16px] mt-[50px] flex flex-col font-normal text-[#FFFFFF] text-center"
                  }
                >
                  {/* @ts-ignore*/}
                  <Link
                    onClick={() => setVisible(false)}
                    to={"page1"}
                    className={"mb-[20px]"}
                  >
                    {staticText.nav1}
                  </Link>
                  {/* @ts-ignore*/}
                  <Link
                    onClick={() => setVisible(false)}
                    to={"page2"}
                    className={"mb-[20px]"}
                  >
                    {staticText.nav2}
                  </Link>
                  {/* @ts-ignore*/}
                  <Link
                    onClick={() => setVisible(false)}
                    to={"page3"}
                    className={"mb-[20px]"}
                  >
                    {staticText.nav3}
                  </Link>
                  {/* @ts-ignore*/}
                  <Link
                    onClick={() => setVisible(false)}
                    to={"page4"}
                    className={"mb-[20px]"}
                  >
                    {staticText.nav4}
                  </Link>
                  {/* @ts-ignore*/}
                  <Link
                    onClick={() => setVisible(false)}
                    to={"page5"}
                    className={"mb-[20px]"}
                  >
                    {staticText.nav5}
                  </Link>
                  {/* @ts-ignore*/}
                  <Link
                    onClick={() => setVisible(false)}
                    to={"page6"}
                    className={"mb-[20px]"}
                  >
                    {staticText.nav6}
                  </Link>
                  {/* @ts-ignore*/}
                  <a
                    onClick={() => setVisible(false)}
                    href={
                      "https://bscscan.com/address/0x838f181160fd7c139D86caF47D3ccD3cEba5484c#code"
                    }
                    className={"mb-[20px]"}
                  >
                    {staticText.nav7}
                  </a>
                </div>
              </div>
              <div>
                {/* <div
                  className={
                    "text-[12px] font-normal text-[#FFFFFF] flex items-center"
                  }
                >
                  <div
                    onClick={() => handleClick("zh")}
                    className={"cursor-pointer"}
                  >
                    繁体中文
                  </div>
                  <div className={"mx-[10px]"}>|</div>
                  <div
                    onClick={() => handleClick("en")}
                    className={"cursor-pointer"}
                  >
                    EN
                  </div>
                </div> */}
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
  return (
    <div className={`navbar ${isSticky ? "sticky" : ""}`}>
      <div className={"h-[80px] bg-black flex justify-center items-center"}>
        <div className={"lg:w-[1400px] flex justify-between items-center"}>
          <Image
            className={"w-[140px] h-[48px] cursor-pointer"}
            src={LogoIcon}
            alt=""
            width={140}
            height={48}
          />
          <div className={"flex text-[16px] font-normal text-[#FFFFFF]"}>
            {/* @ts-ignore*/}
            <Link
              onClick={() => navClick("page1")}
              to={"page1"}
              className={"cursor-pointer title-box"}
            >
              <div>{staticText.nav1}</div>
              {/*{*/}
              {/*	currentpage === 'page1' ? (*/}
              {/*		<div classname={'bg-[#3347ff] rounded-[10px] w-full h-[4px] mt-[5px]'}></div>*/}
              {/*	) : (*/}
              {/*		<div classname={'bg-[#737373] rounded-[10px] title-line'}></div>*/}
              {/*	)*/}
              {/*}*/}
              <div className={"bg-[#737373] rounded-[10px] title-line"}></div>
            </Link>
            {/* @ts-ignore*/}
            <Link
              onClick={() => navClick("page2")}
              to={"page2"}
              className={"ml-[32px] cursor-pointer title-box"}
            >
              <div>{staticText.nav2}</div>
              {/*{*/}
              {/*	currentPage === 'page1' ? (*/}
              {/*		<div className={'bg-[#3347FF] rounded-[10px] w-full h-[4px] mt-[5px]'}></div>*/}
              {/*	) : (*/}
              {/*		<div className={'bg-[#737373] rounded-[10px] title-line'}></div>*/}
              {/*	)*/}
              {/*}*/}
              <div className={"bg-[#737373] rounded-[10px] title-line"}></div>
            </Link>
            {/* @ts-ignore*/}
            <Link
              onClick={() => navClick("page3")}
              to={"page3"}
              className={"ml-[32px] cursor-pointer title-box"}
            >
              <div>{staticText.nav3}</div>
              {/*{*/}
              {/*	currentPage === 'page1' ? (*/}
              {/*		<div className={'bg-[#3347FF] rounded-[10px] w-full h-[4px] mt-[5px]'}></div>*/}
              {/*	) : (*/}
              {/*		<div className={'bg-[#737373] rounded-[10px] title-line'}></div>*/}
              {/*	)*/}
              {/*}*/}
              <div className={"bg-[#737373] rounded-[10px] title-line"}></div>
            </Link>
            {/* @ts-ignore*/}
            <Link
              onClick={() => navClick("page4")}
              to={"page4"}
              className={"ml-[32px] cursor-pointer title-box"}
            >
              <div>{staticText.nav4}</div>
              {/*{*/}
              {/*	currentPage === 'page1' ? (*/}
              {/*		<div className={'bg-[#3347FF] rounded-[10px] w-full h-[4px] mt-[5px]'}></div>*/}
              {/*	) : (*/}
              {/*		<div className={'bg-[#737373] rounded-[10px] title-line'}></div>*/}
              {/*	)*/}
              {/*}*/}
              <div className={"bg-[#737373] rounded-[10px] title-line"}></div>
            </Link>
            {/* @ts-ignore*/}
            <Link
              onClick={() => navClick("page5")}
              to={"page5"}
              className={"ml-[32px] cursor-pointer title-box"}
            >
              <div>{staticText.nav5}</div>
              {/*{*/}
              {/*	currentPage === 'page1' ? (*/}
              {/*		<div className={'bg-[#3347FF] rounded-[10px] w-full h-[4px] mt-[5px]'}></div>*/}
              {/*	) : (*/}
              {/*		<div className={'bg-[#737373] rounded-[10px] title-line'}></div>*/}
              {/*	)*/}
              {/*}*/}
              <div className={"bg-[#737373] rounded-[10px] title-line"}></div>
            </Link>
            {/* @ts-ignore*/}
            <Link
              onClick={() => navClick("page6")}
              to={"page6"}
              className={"ml-[32px] cursor-pointer title-box"}
            >
              <div>{staticText.nav6}</div>
              {/*{*/}
              {/*	currentPage === 'page1' ? (*/}
              {/*		<div className={'bg-[#3347FF] rounded-[10px] w-full h-[4px] mt-[5px]'}></div>*/}
              {/*	) : (*/}
              {/*		<div className={'bg-[#737373] rounded-[10px] title-line'}></div>*/}
              {/*	)*/}
              {/*}*/}
              <div className={"bg-[#737373] rounded-[10px] title-line"}></div>
            </Link>
            {/* @ts-ignore*/}
            <a
              onClick={() => navClick("page7")}
              href={
                "https://bscscan.com/address/0x838f181160fd7c139D86caF47D3ccD3cEba5484c#code"
              }
              className={"ml-[32px] cursor-pointer title-box"}
            >
              <div>{staticText.nav7}</div>
              {/*{*/}
              {/*	currentPage === 'page1' ? (*/}
              {/*		<div className={'bg-[#3347FF] rounded-[10px] w-full h-[4px] mt-[5px]'}></div>*/}
              {/*	) : (*/}
              {/*		<div className={'bg-[#737373] rounded-[10px] title-line'}></div>*/}
              {/*	)*/}
              {/*}*/}
              <div className={"bg-[#737373] rounded-[10px] title-line"}></div>
            </a>
          </div>
          {/* <div
            className={
              "text-[12px] font-normal text-[#FFFFFF] flex items-center"
            }
          >
            <div onClick={() => handleClick("zh")} className={"cursor-pointer"}>
              繁体中文
            </div>
            <div className={"mx-[10px]"}>|</div>
            <div onClick={() => handleClick("en")} className={"cursor-pointer"}>
              EN
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default memo(NavBar);
