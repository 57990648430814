import { useLanguage } from "@/contexts/LanguageContext";
import { useEffect, useState } from "react";

export interface IStaticText {
  nav1: string;
  nav2: string;
  nav3: string;
  nav4: string;
  nav5: string;
  nav6: string;
  nav7: string;
  page1: string;
  page2: string;
  page3: string;
  page4: string;
  page5: string;
  page6: string;
  page7: string;
  page8: string;
  page9: string;
  page10: string;
  page11: string;
  page12: string;
  page13: string;
  page14: string;
  page15: string;
  page16: string;
  page17: string;
  page18: string;
  page19: string;
  page20: string;
  page21: string;
  page22: string;
  page23: string;
  page24: string;
  page25: string;
  page26: string;
  page27: string;
  page28: string;
  page29: string;
  page30: string;
  page31: string;
  page32: string;
  page33: string;
  page34: string;
  page35: string;
  page36: string;
  page37: string;
  page38: string;
  page39: string;
  page40: string;
  page41: string;
  page42: string;
  page43: string;
  page44: string;
  page45: string;
  page46: string;
  page47: string;
  page48: string;
  page49: string;
  page50: string;
  page51: string;
  page52: string;
  page53: string;
  page54: string;
  page55: string;
  page56: string;
  page57: string;
  page58: string;
  page59: string;
  page60: string;
  page61: string;
  page62: string;
}

const staticContent: {
  en: IStaticText;
  [x: string]: IStaticText;
} = {
  en: {
    nav1: "Home",
    nav2: "Ecological advantage",
    nav3: "Technical Features",
    nav4: "Governance Projects",
    nav5: "Ecological development",
    nav6: "Wallet Download",
    nav7: "Open source link",
    page1: "Explore the MP Chain Ecosystem",
    page2: "New decentralized system platform",
    page3: "Whitepaper",
    page4: "Next Generation",
    page5: "Internet's",
    page6: "Cornerstone",
    page7:
      "The MP platform was founded by a laboratory established by top talents in the blockchain field in Malaysia and is committed to creating a safe, transparent and efficient digital asset ecosystem. The RUNS coin developed based on Binance Chain is the core asset of the entire ecosystem. MP's multi-ecosystem provides global players with more ways to play and enjoy decentralized financial services; We always adhere to the principle of decentralization, allowing everyone to Every participant can play freely on this ecological platform and jointly create value.MP ecology is a decentralized RUNS coin distribution game. All game rules are written into smart contracts and deployed on the BSC public chain network. The contract exists permanently, allowing the platform to operate permanently without account information, and is always anonymous and safe;",
    page8:
      "The professional network architecture is designed to achieve efficient data processing and excellent scalability while maintaining decentralization and security. The design of the network structure considers various factors, including node distribution, data transmission efficiency, and fault tolerance mechanisms. The blockchain ecosystem provides application developers with a complete set of infrastructure tools, supporting advanced scalability and interoperability to help developers easily access the entire blockchain ecosystem.",
    page9: "Open",
    page10:
      "Breaking traditional barriers, a diversified decentralized ecology is available for players to choose from",
    page11: "Free",
    page12:
      "Provide decentralized platforms and tools to allow players to independently manage digital assets",
    page13: "Efficient",
    page14:
      "Utilize distributed technology to eliminate intermediate links and data is directly uploaded to the chain.",
    page15: "Safety",
    page16:
      "Using encryption and decentralized technology, no authorization is required, and users control their own assets, providing a safe and trustworthy environment.",
    page17: "Smart contract",
    page18:
      "Complete smart contract automatic execution mechanism to realize smart automation on the chain",
    page19: "Data storage",
    page20:
      "The MP platform adopts a decentralized data storage mechanism to store data on multiple nodes in the network.",
    page21: "Integrated",
    page22: "Speed, Privacy, Scalability, and Liquidity",
    page23: "Hundreds of Times Faster than Ethereum",
    page24: "Average Block Size",
    page25: "Block Interval Time",
    page26: "Validator Nodes",
    page27: "Our Features",
    page28: "Create and promote MP ecological development",
    page29:
      "The development of MP ecology is part of the continuous innovation and progress of blockchain technology. Its technical architecture, consensus mechanism and ecosystem design have demonstrated significant innovation and potential. MP Ecosystem will continue to promote the popularization and application of blockchain technology and create more value for users and society.",
    page30: "Advanced Consensus Mechanism",
    page31:
      "Adopts new hybrid consensus algorithms like POSA upgraded TPOS, effectively balancing security and efficiency, providing higher transaction processing speed, and network stability.",
    page32: "Flexible Technical Architecture",
    page33:
      "The application of layered architecture and side-chain technology improves the scalability and adaptability of the system, enabling the MP ecosystem to support a wide range of application scenarios.",
    page34: "Rich Ecosystem",
    page35:
      "Diversified decentralized application ecosystem construction provides users with rich choices and innovative experiences. The ecosystem will help blockchain technology enter more traditional fields and drive industry value development.",
    page36: "RUNS total circulation and distribution",
    page37:
      "The main functions of RUNS tokens include paying network transaction fees, participating in network governance, and motivating ecosystem participants.",
    page38:
      "and as interactive credentials for various applications and services. These functions ensure the efficient operation of the MP ecosystem and the healthy development of the ecosystem",
    page39: "MP Ecosystem:69.59%",
    page40: "NFT movement output: 8%",
    page41: "Ecosystem Fund: 8%",
    page42: "Promotion and Marketing Fund: 6%",
    page43: "Risk Warning and Disclaimer",
    page44:
      "The MP ecosystem is open and anyone can participate in the development of the ecosystem. The operation and maintenance mechanism uses virtual digital assets as vouchers instead of a monetary reward mechanism. RUNS coins are used as ecosystem maintenance reward tokens to satisfy the needs of the system and other societies. Subject to the subject's resource exchange needs, subject to the laws and regulations of the region where the application service is located, the market open trading of RUNS tokens is affected by market conditions, and the price may fluctuate.",
    page45: "The powerful ecosystem of MP platform",
    page46:
      "Enhance community participation and governance, establish a more open and collaborative ecosystem",
    page47: "NFT movement",
    page48: "Matrix ecology",
    page49: "Staking Ecosystem",
    page50: "carbon neutral",
    page51: "LP Liquidity Mining",
    page52: "Future ecology",
    page53: "Decentralized Wallet, Ultimate Security Experience",
    page54:
      "No wallet authorization is required, your assets are under your own control",
    page55: "Contact Us",
    page56: "Email",
    page57: "ecology",
    page58: "Super Entertainment",
    page59: "Travel Exploration",
    page60: "Founding Team:3.31%",
    page61: "Advisor:0.1%",
    page62: "Capital institutions:5%",
  },
  // zh: {
  //   nav1: "首頁",
  //   nav2: "生態優勢",
  //   nav3: "技術特色",
  //   nav4: "治理專案",
  //   nav5: "生態發展",
  //   nav6: "錢包下載",
  //   nav7: "開源鏈接",
  //   page1: "探索 MP Chain 生態系統",
  //   page2: "全新去中心化系统平台",
  //   page3: "白皮書",
  //   page4: "下一代",
  //   page5: "互聯網的",
  //   page6: "基石",
  //   page7:
  //     "MP平台由馬來西亞區塊鏈領域頂尖人才組建的實驗室創辦，致力於打造一個安全、透明、高效的數字資產生態圈。 基於幣安鏈開發的RUNS幣，是整個生態系統的核心資產，MP的多生態系統為全球玩家提供更多的玩法，暢想去中心化的金融服務； 我們始終堅持去中心化的原則，讓每一位參與者都能够在這個生態平台上自由發揮，共同創造價值。MP生態是一個去中心化的RUNS幣分配遊戲，把所有的遊戲規則寫入智能合約，部署在BSC公鏈網絡上，該合約永久存在，讓平台永久運營，無需帳戶信息，永遠匿名安全；",
  //   page8:
  //     "專業的網絡結構設計旨在實現高效的數據處理和優秀的擴展性，同時保持網絡的去中心化和安全性。網絡結構的設計考慮了多種因素，包括節點分佈、數據傳輸效率和容錯機制等。區塊鏈生態為應用開發者提供全套基礎設施工具，更支持先進的擴展和互操作性，幫助開發者輕鬆觸達整個區塊鏈生態系統。",
  //   page9: "開放",
  //   page10: "打破傳統壁壘，多元化的去中心化生態供玩家選擇",
  //   page11: "自由",
  //   page12: "提供去中心化的平台和工具，玩家實現自主管理數位資產",
  //   page13: "高效率",
  //   page14: "利用分散式技術，消除中間環節，資料直接上鍊",
  //   page15: "安全",
  //   page16:
  //     "採用加密和去中心化技術，無需授權，使用者自我掌控資產，提供安全且可信的環境",
  //   page17: "智能合約",
  //   page18: "完整的智能合約自動執行機制，實現鏈上智慧自動化",
  //   page19: "資料儲存",
  //   page20:
  //     "MP平台採用了分散式資料儲存機制，將資料分散儲存在網路中的多個節點上",
  //   page21: "集於一體",
  //   page22: "速度、隱私、擴展性與流動性",
  //   page23: "快Ethereum數百倍",
  //   page24: "平均區塊大小",
  //   page25: "出塊間隔時間",
  //   page26: "驗證人節點",
  //   page27: "我們的特色",
  //   page28: "創建並推動MP生態發展",
  //   page29:
  //     "MP生態的發展是區塊鏈技術不斷創新和進步的一部分，其技術架構、共識機制和生態系統設計展現了顯著的創新和潛力。MP生態將繼續推動區塊鏈技術的普及和應用，為用戶和社會創造更多的價值。",
  //   page30: "先進的共識機制",
  //   page31:
  //     "採用POSA升級TPOS等新型混合共識算法，有效平衡了安全性和效率，提供了更高的交易處理速度和網絡穩定性。",
  //   page32: "靈活的技術架構",
  //   page33:
  //     "分層架構和側鏈技術的應用，提高了系統的可擴展性和適應性，使得MP生態能夠支持廣泛的應用場景。",
  //   page34: "豐富的生態系統",
  //   page35:
  //     "多元化的去中心化應用生態建設，為用戶提供豐富的選擇和創新體驗，生態系統將幫助區塊鏈技術進入更多傳統領域推動行業價值發展。",
  //   page36: "RUNS總發行量與分配",
  //   page37:
  //     "RUNS代幣的主要功能包括支付網絡交易費、參與網絡治理、激勵生態系統參與者，",
  //   page38:
  //     "以及作為各種應用和服務的交互憑證。這些功能確保了MP生態的高效運行和生態系統的健康發展",
  //   page39: "MP生態系統:69.59%",
  //   page40: "NFT運動產出:8%",
  //   page41: "生態系統基金:8%",
  //   page42: "推廣行銷基金:6%",
  //   page43: "風險提示及免責聲明",
  //   page44:
  //     "MP生態系統是開放的，任何人皆可參與生態系統發展，營運維護機制均採用虛擬數位資產為憑證，而非採用貨幣獎勵機制，RUNS幣作為生態系統維護獎勵代幣，為滿足系統與其它社會主體的資源交換需求，在遵守應用服務所在地區的法律法規的前提下，RUNS代幣的市場開放交易受市場行情影響，價格可能會波動。",
  //   page45: "MP平台的強大生態",
  //   page46: "增強社區參與和治理，建立更加開放和協作的生態系統",
  //   page47: "NFT運動",
  //   page48: "矩陣生態",
  //   page49: "質押生態",
  //   page50: "碳中和",
  //   page51: "LP流動性挖礦",
  //   page52: "未來生態",
  //   page53: "去中心化錢包，極致安全體驗",
  //   page54: "无需钱包授权，资产由您自行掌控",
  //   page55: "联系我们",
  //   page56: "电子邮箱",
  //   page57: "生態",
  //   page58: "超級娛樂",
  //   page59: "旅行探索",
  //   page60: "創始團隊:3.31%",
  //   page61: "顧問:0.1%",
  //   page62: "資本機構:5%",
  // },
};

export function useStaticContent() {
  const { language } = useLanguage();
  const [data, setData] = useState<IStaticText>(staticContent[language]);
  useEffect(() => {
    setData(staticContent[language]);
  }, [language, setData]);
  return data;
}
